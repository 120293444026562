<template>
  <v-card elevation="0" class="transparent px-2 py-2">
    <div class="fs-24 color-state font-weight-bold mb-2">
      <span>Account</span>
    </div>
    <v-card-text class="px-0 bg-white">
      <v-card elevation="0" class="">
        <v-tabs id="" v-model="tab" background-color="transparent" class="">
          <v-tab class="text-capitalize">Informasi Pribadi</v-tab>
          <v-tab v-show="showEmail" class="text-capitalize"
            >Ubah Password</v-tab
          >
        </v-tabs>
        <v-divider class="mb-4"></v-divider>
        <v-tabs-items v-model="tab" class="setting transparent">
          <v-tab-item>
            <v-row>
              <v-col cols="12" md="2" class="">
                <div class="profile ma-auto text-center">
                  <div
                    class="
                      pa-10
                      bg-grey
                      rounded-circle
                      d-inline-block
                      mb-3
                      fs-20
                    "
                  >
                    <v-icon>ri-user-line</v-icon>
                  </div>
                  <!-- <p class="fs-20 color-state font-weight-medium">Username</p> -->
                </div>
              </v-col>
              <v-col cols="12" md="7" class="ps-2">
                <v-alert class="ms-4 fs-12 accent-blue me-2">
                  <div class="d-flex">
                    <v-icon class="me-2 color-blue align-start"
                      >ri-information-fill</v-icon
                    >
                    <p class="color-blue mb-0">
                      Harap data di bawah ini diisi dengan informasi yang
                      sebenarnya dan tanpa kesalahan karena data yang sudah
                      terinput tidak dapat dirubah kembali dan data ini
                      merupakan data identitas yang akan tercetak pada
                      sertifikat. 
                      <!-- Jika terdapat kendala atau hal yang belum
                      dimengerti bisa hubungi admin via email ke shop@yec.co.id. -->
                    </p>
                  </div>
                </v-alert>
                <v-alert
                  class="ms-4"
                  type="error"
                  text
                  v-html="response"
                  v-show="response !== null">
                  </v-alert>
                <v-alert
                  class="ms-4"
                  type="success"
                  text
                  v-show="responsesukses !== null"
                  >{{ responsesukses }}</v-alert
                >
                <v-form
                  class="ps-2 pb-5"
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <!-- <v-row> -->
                    
                    <v-col class="pb-0">
                    <div class="mb-2">
                      <span class="font-weight-medium">Nomor Induk Mahasiswa</span> &nbsp;
                      <span class="text--secondary">(NIM)</span>
                      <span class="font-italic red--text caption float-right">(Wajib)</span>
                    </div>
                    <v-text-field
                      class="py-0"
                      v-model="nim"
                      placeholder="Tuliskan nomor induk mahasiswa disini"
                      :readonly="changeRead"
                      counter="20"
                      required
                      outlined
                      hide-details="auto"
                      :rules="nimRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <div class="mb-2">
                      <span class="font-weight-medium">Email</span>
                    </div>
                    <v-text-field
                      class=""
                      v-model="user.email"
                      placeholder="Masukkan alamat email"
                      required
                      outlined
                      readonly
                      hide-details="auto"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <div class="mb-2">
                      <span class="font-weight-medium">
                        Nama Lengkap
                        <!-- <span class="text--secondary">(Tanpa gelar)</span> -->
                      </span>
                    </div>
                    <v-text-field
                      class=""
                      v-model="fullname"
                      placeholder="Masukkan nama lengkap"
                      :readonly="changeRead"
                      required
                      outlined
                      hide-details="auto"
                      :rules="nameRules"
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <div class="mb-2">
                      <div class="font-weight-medium">
                        No. Telp <span class="font-italic teal--text caption">(Optional)</span>
                      </div>
                    </div>
                    <v-text-field
                      class=""
                      v-model="phone"
                      placeholder="Masukkan No. Telp"
                      :readonly="changeRead"
                      outlined
                      hide-details="auto">
                    </v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <div class="mb-2">
                      <span class="font-weight-medium">Tempat Lahir</span>
                    </div>
                    <v-text-field
                      class=""
                      v-model="tempat_lahir"
                      placeholder="Masukkan tempat lahir"
                      :readonly="changeRead"
                      outlined
                      hide-details="auto"
                      :rules="placeRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="pb-0">
                    <div class="mb-2">
                      <span class="font-weight-medium"
                        >Tanggal Lahir
                        <span class="text--secondary"
                          >(Format: YYYY-MM-DD)</span
                        ></span
                      >
                    </div>

                    <v-text-field
                      v-if="user.is_updated_profile === true"
                      v-model="date"
                      :rules="dateRules"
                      :readonly="changeRead"
                      outlined
                    ></v-text-field>
                    <v-menu
                      v-if="user.is_updated_profile === false"
                      ref="menus"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          :rules="dateRules"
                          :readonly="changeRead || user.is_updated_profile === true"
                          required
                          v-bind="attrs"
                          placeholder="YYYY-MM-DD"
                          append-icon="ri-calendar-line"
                          outlined
                          v-on="on">
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        ref="menu"
                        @input="menu = false"
                        :active-picker.sync="activePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save">
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-btn
                    large
                    elevation="0"
                    color="primary"
                    class="ms-3"
                    :disabled="changeStatus || !valid"
                    @click="dialog.confirm = true"
                  >
                    Simpan
                  </v-btn>
                </v-form>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item v-show="showEmail">
            <v-row>
              <v-col cols="12" md="2" class="">
                <div class="profile ma-auto text-center">
                  <div
                    class="
                      pa-10
                      bg-grey
                      rounded-circle
                      d-inline-block
                      mb-3
                      fs-20
                    "
                  >
                    <v-icon>ri-user-line</v-icon>
                  </div>
                </div>
              </v-col>
              <v-col cols="" md="7" class="ps-2">
                <v-alert
                  class="ms-4"
                  type="success"
                  text
                  v-show="resSuccess !== null"
                  >{{ resSuccess }}</v-alert
                >
                <v-alert
                  class="ms-4"
                  type="error"
                  text
                  v-show="resFailed !== null"
                  >{{ resFailed }}</v-alert
                >
                <v-form
                  class="ps-2 pb-5"
                  ref="formChange"
                  v-model="validPsw"
                  lazy-validation
                >
                  <v-col class="mb-3">
                    <div class="mb-2">
                      <span class="font-weight-medium">Password Sekarang</span>
                    </div>
                    <v-text-field
                      class=""
                      v-model="current_psw"
                      placeholder="Masukkan password lama anda"
                      required
                      :rules="current_rules"
                      ref="input"
                      outlined
                      hide-details="auto"
                      :type="show ? 'text' : 'password'"
                      :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                      @click:append="show = !show"
                    ></v-text-field>
                  </v-col>
                  <v-col class="mb-3">
                    <div class="mb-2">
                      <span class="font-weight-medium">Password Baru</span>
                    </div>
                    <v-text-field
                      class=""
                      v-model="new_psw"
                      placeholder="Masukkan password baru anda"
                      required
                      :rules="new_rules"
                      ref="input"
                      outlined
                      hide-details="auto"
                      :type="show2 ? 'text' : 'password'"
                      :append-icon="show2 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                      @click:append="show2 = !show2"
                    ></v-text-field>
                  </v-col>
                  <v-col class="mb-3">
                    <div class="mb-2">
                      <span class="font-weight-medium"
                        >Konfirmasi Password</span
                      >
                    </div>
                    <v-text-field
                      class=""
                      v-model="confirm_psw"
                      placeholder="Masukkan ulang password anda"
                      required
                      :rules="confirm_rules"
                      ref="input"
                      outlined
                      hide-details="auto"
                      :type="show3 ? 'text' : 'password'"
                      :append-icon="show3 ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                      @click:append="show3 = !show3"
                    ></v-text-field>
                  </v-col>
                  <v-btn
                    large
                    elevation="0"
                    :disabled="!confirm_psw"
                    color="primary"
                    class="ms-3"
                    @click="changePassword()"
                  >
                    Update
                  </v-btn>
                  <!-- </v-col> -->
                  <!-- </v-row> -->
                </v-form>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-card-text>
    <v-dialog v-model="dialog.confirm" width="500">
      <v-card>
        <v-card-title class="font-weight-medium fs-24 color-state">
          Perhatian
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="fs-14 py-1">
          <span class="color-disable"
            >Apakah informasi yang anda masukkan sudah sesuai dan benar adanya,
            jika iya silahkan klik benar
          </span>
          <v-alert dense text color="warning" class="fs-12 px-1">
            <v-icon color="warning">mdi-information-outline</v-icon>
            <strong> Data yang sudah disimpan tidak dapat diubah. </strong>
          </v-alert>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            outlined
            class="fs-18 pa-4 py-6"
            @click="dialog.confirm = false"
          >
            Batalkan
          </v-btn>
          <v-btn color="primary" class="fs-18 pa-4 py-6" @click="saveSetting()">
            Benar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "account-setting",
  data() {
    return {
      activePicker: null,

      showEmail: false,
      show: false,
      show2: false,
      show3: false,
      tab: null,
      valid: true,
      menu: false,
      date: null,
      fullname: "",
      email: "",
      tempat_lahir: "",
      phone: "",
      nim: "",
      nimRules: [
        (v) => !!v || "Nomor Induk Mahasiswa Tidak Boleh Kosong",
      ],
      nameRules: [(v) => !!v || "Nama Lengkap Tidak Boleh Kosong"],
      emailRules: [
        (v) => !!v || "E-mail Tidak Boleh Kosong",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      placeRules: [(v) => !!v || "Tempat Lahir Tidak Boleh Kosong"],
      dateRules: [
        (v) => {
          if (!v) return "Tanggal Lahir Tidak Boleh Kosong";
          const fullDate = v.split("-");
          const year = fullDate[0];
          const month = fullDate[1];
          const day = fullDate[2];

          if (parseInt(year) < 1900)
            return "Tahun tidak boleh kurang dari 1900";
          if (!parseInt(year) || year.length != 4)
            return "Format tanggal tidak valid";
          if (!parseInt(month) || month.length != 2)
            return "Format tanggal tidak valid";
          if (!parseInt(day) || day.length != 2)
            return "Format tanggal tidak valid";

          if (!Date.parse(`${year}-${month}-${day}`)) {
            return "Format tanggal tidak valid";
          }

          return true;
        },
      ],

      validPsw: true,
      current_psw: "",
      new_psw: "",
      confirm_psw: "",
      current_rules: [
        (v) => !!v || "Password Tidak Boleh Kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],
      new_rules: [
        (v) => !!v || "Password Tidak Boleh Kosong",
        (v) => (v && v.length >= 8) || "Password minimal harus 8 karakter",
      ],

      submiting: false,
      dialog: {
        confirm: false,
      },
      response: null,
      responsesukses: null,
      resSuccess: null,
      resFailed: null,
      changeRead: false,
      changeStatus: false,
      detail: {},
    };
  },
  components: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.menu.activePicker = "YEAR"));
    },
    // menu (val) {
    //   val && setTimeout(() => (this.activePicker = 'YEAR'))
    // },
    fullname(value) {
      this.fullname = value
        .split(" ")
        .map((val) => {
          return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
        })
        .join(" ");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    confirm_rules() {
      return [
        () =>
          this.new_psw === this.confirm_psw || "Konfirmasi password tidak sama",
        (v) => !!v || "Konfirmasi Password Tidak Boleh Kosong",
      ];
    },
  },
  created() {
    // console.log('token',this.user.is_updated_profile);
    this.getDetail();
    // this.changePsw();
  },
  mounted() {
    this.email = this.user.email;
    this.fullname = this.user.fullname;
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    // changePsw(){
    //   if (this.user.provider == 'email') {
    //     this.showEmail=true;
    //   }else{
    //     this.showEmail=false;
    //   }
    // },
    getDetail() {
      this.axios.get(`/users/v1/member/detail/`).then((response) => {
        let res = response.data;
        // console.log(res);
        this.loading = false;
        if (response.status == 200) {
          this.loading = false;
          this.detail = res.data;
          // console.log('detail',this.detail);
          this.getDisable();
        } else if (response.status == 400) {
          // console.log("hasil2", response);
        }
      });
    },
    getDisable() {
      if (this.detail.is_updated_profile === true) {
        this.changeStatus = true;
        this.changeRead = true;
        this.fullname = this.detail.fullname;
        this.date = this.detail.date_of_birth;
        this.tempat_lahir = this.detail.place_of_birth;
        this.phone = this.detail.phone_number;
        this.nim = this.detail.meta.nim
      }
      this.email = this.user.email;
    },
    saveSetting() {
      this.dialog.confirm = false;
      if (!this.$refs.form.validate()) {
        return;
      }
      // console.log("date", this.date);
      this.submiting = true;
      const sk = this.$refs.form.validate();
      if (sk == true) {
        this.submiting = false;
        this.changeRead = true;
        let fullname = this.fullname;
        let date_of_birth = this.date;
        let place_of_birth = this.tempat_lahir;
        let phone = this.phone;
        let meta = {
          nim: this.nim
        }

        this.axios
          .put(
            `/users/v1/member/update-profile/`,
            { fullname, date_of_birth, place_of_birth, phone, meta },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // console.log(res);
            this.loading = false;

            this.$store.dispatch("get_user");
            this.loading = false;
            this.responsesukses = "Data Anda berhasil disimpan";
            this.getDetail();
            setTimeout(() => {
              this.responsesukses = null;
              this.$router.push("/epot");
              response
            }, 3000);
          })
          .catch(error => {
          if (error.response) {
            let errorCode = error.response.status
            let errorData = error.response.data.validation;

            if (errorCode == 400) {
              window.scrollTo(0, 0);
              this.submiting = false;
              this.loading = false;
              this.changeRead = false
              this.email = this.user.email;
              this.response = ""
              Object.keys(errorData).forEach(item => {
                this.response += errorData[item] + `</br>`;
              })
              setTimeout(() => {
                this.response = null;
              }, 5000);
            }
          }
        })
      } else {
        this.submiting = false;
        this.email = this.user.email;
        this.fullname = "";
        this.date = null;
        this.tempat_lahir = "";
        this.phone = "";
        this.response = "Silahkan cek kembali data Anda";
        setTimeout(() => {
          this.response = null;
        }, 3000);
      }
    },
    changePassword() {
      this.submiting = true;
      if (this.$refs.formChange.validate()) {
        this.submiting = false;
        let current_password = this.current_psw;
        let new_password = this.new_psw;
        let conf_new_password = this.confirm_psw;
        // this.$refs.formChange.reset();
        // console.log('result change', {current,newpass,confirm});

        this.axios
          .put(
            `/users/v1/member/change-password/`,
            { current_password, new_password, conf_new_password },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            // let res = response.data;
            // console.log("change", response);
            this.loading = false;
            if (response.status == 200) {
              this.loading = false;
              // console.log("sukses", res.data);
              this.resSuccess = "Password Anda berhasil diubah ";
              setTimeout(() => {
                this.resSuccess = null;
              }, 3000);
              let email = this.user.email;
              let password = this.new_psw;
              // console.log('password',password)
              this.$store.dispatch("login", { email, password }).then(() => {
                this.submiting = false;
                if (localStorage.getItem("ytc_lms_redirect") != null) {
                  this.$router.push(localStorage.getItem("ytc_lms_redirect"));
                  localStorage.removeItem("ytc_lms_redirect");
                }
              });
            }
          })
          .catch(() => {
            this.submiting = false;
            this.$refs.form.reset();
            this.resFailed = "Current password Anda salah";
            setTimeout(() => {
              this.resFailed = null;
            }, 3000);
          });
      } else if (
        this.new_password.length < 8 ||
        this.conf_new_password.length < 8
      ) {
        this.resFailed = "Password kurang dari 8 character";
        this.submiting = false;
        setTimeout(() => {
          this.resFailed = null;
        }, 3000);
      } else if (this.conf_new_password != this.new_password) {
        this.resFailed = "Password kurang dari 8 character";
        this.submiting = false;
        setTimeout(() => {
          this.resFailed = null;
        }, 3000);
      } else {
        this.submiting = false;
        this.resFailed = "Kolom password tidak boleh kosong";
        this.$refs.form.reset();
        setTimeout(() => {
          this.resFailed = null;
        }, 3000);
      }
      this.$refs.formChange.reset();
    },
  },
};
</script>

<style>
.accent-blue {
  background-color: #d4e5fe !important;
  /*opacity: 0.4;*/
  border-radius: 4px;
}
</style>