<template>
  <v-card
    rounded="lg"
    elevation="0"
    class="col-md-10 ma-auto my-10 px-2 py-10 text-center"
  >
    <div class="fs-24 color-state font-weight-bold mb-2">
      <span>{{ response }}</span>
      
    </div>
    <div class="">
      <p class="color-disable mb-0 fs-18">{{ information }}</p>
      <div class="col-md-8 ma-auto color-disable mb-0 fs-18" style="white-space: pre-line">
        <ol type="number" class="text-center">
          <li style="display: inline;">Di kanan atas, Klik menu <v-icon>mdi-dots-vertical</v-icon>  <v-icon>mdi-chevron-right</v-icon> </li>
          <li style="display: inline;">Pilih Settings <v-icon>mdi-chevron-right</v-icon> </li>
          <li style="display: inline;">Pilih Privacy and security <v-icon>mdi-chevron-right</v-icon> </li>
          <li style="display: inline;">Pilih Site Settings <v-icon>mdi-chevron-right</v-icon> </li>
          <li style="display: inline;">Pilih Sound <v-icon>mdi-chevron-right</v-icon> </li>
          <li style="display: inline;">Aktifkan "Allow sites to play sound (recommended)"</li>
        </ol>
      </div>
    </div>
    <v-card-text class="pa-0">
      <div class="content-check">
        <div class="icon-cek">
          <div class="pst" v-show="showIcon">
            <v-progress-circular
              indeterminate
              :size="50"
              color="#EFF2F7"
              class="my-auto"
            >
              <v-icon class="color-disable">ri-customer-service-fill</v-icon>
            </v-progress-circular>
          </div>
          <div class="pst" v-show="showAudio" style="top:30%;">
            <label>
              <v-btn
                ref="audio"
                color="primary"
                fab
                dark
                large
                @click.prevent="
                  playSound('https://storage.googleapis.com/yec-cdn/5fad2ff6d9c15a03ece5272a/videos/1617006675978954662-whatsapp-audio-2021-03-29-at-15-25-59.mpeg')
                "
              >
                    <!-- 'http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3' -->
                <v-icon>ri-play-circle-line</v-icon>
              </v-btn>
              <p class="color-state font-weight-bold fs-18 my-2">Play</p>
            </label>
          </div>
        </div>
        <v-col cols="" md="10" class="ma-auto">
          <p class="color-disable mb-1 fs-18">
            Jika berhasil mendengarkan audio , Anda bisa melanjutkan ke halaman berikutnya dengan tekan tombol Next dibawah ini.
          </p>
          <router-link
            class="mb-1 v-btn theme--light elevation-0 v-size--default primary "
            :to="{ name: 'epot-webcam-check', params: { id: this.$route.params.id } }"
            @click.stop
            >Next
          </router-link>
          <v-alert text warning>
            <span class="fs-14 color-warning-cutsom"
              >Pastikan Anda mendengar audio yang terputar di halaman ini. Atur
              besar kecil audio dengan kontrol volume di perangkat Anda.
              Gunakan headset/earphone untuk pendengaran yang lebih baik.
            </span>
          </v-alert>
          
        </v-col>
        <!-- <p class="font-weight-medium color-state">
          Mengalami kendala? Hubungi CS di email shop@yec.co.id
        </p> -->
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "epot-cekheadset",
  component: {},
  data: function () {
    return {
      checkbox: false,
      showAudio: true,
      showIcon: false,
      response: "",
      information: "",
    };
  },
  created() {
    window.history.forward();
  },
  mounted() {
    // window.onbeforeunload = function (e) {
    //   e.preventDefault();
    //   e.returnValue = "";
    // };
    // this.$route.meta.prevent_move = true;
    // this.response = "Sedang memeriksa perangkat anda..";
    this.response = "Audio menyambungkan perangkat anda";
    // this.information =
    //   "Proses ini berjalan secara otomatis dan mungkin membutuhkan waktu beberapa saat. Harap menunggu.";
    this.information =
      "Jika Anda tidak dapat mendengarkan audio pada halaman ini.";
    // navigator.mediaDevices
    //   .getUserMedia({ audio: true })
    //   .then((mediaStream) => {
    //     this.$refs.audio.srcObject = mediaStream;
    //     this.response = "Berhasil menyambungkan perangkat anda";
    //     this.showAudio = true;
    //     this.showIcon = false;
    //     let a = this.$route.params.id;
    //     console.log("id exam", a);
    //     setTimeout(() => {
    //       window.onbeforeunload = null;
    //       this.$route.meta.prevent_move = false;
    //       this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
    //     }, 10000);
    //   })
    //   .catch((error) => {
    //     this.response = "Gagal menyambungkan perangkat anda";
    //     this.information =
    //       "Silahkan Cek Kembali Permission Access Device di browser anda.";
    //     console.error("getUserMedia() error:", error);
    //   });
  },
  beforeDestroy() {
    // window.onbeforeunload = null;
  },
  methods: {
    prevent_unload(e) {
      e.preventDefault();
      e.returnValue = "";
    },
    goTest() {
      this.$router.push("/");
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
  },
};
</script>

<style>
</style>