<template>
  <v-row align-content="center" justify="center" no-gutters style="background-color: white">
    <v-col class="pa-0" md="6" lg="5">
      <v-row>
        <v-col class="white px-12" md="12" style="height: 100vh">
          <!-- <v-spacer class="py-10"></v-spacer> -->
          <v-row class="d-flex justify-center align-center my-10">
            <v-col cols="12" class="text-center">
              <img width="100" :src="require('@/assets/images/logo-pnk.png')" />
            </v-col>
          </v-row>

          <div class="text-h4 font-weight-medium mb-4 text-center">LOGIN</div>

          <v-form
            class="px-8"
            @submit.prevent="submit"
            lazy-validation
            ref="form"
            v-model="valid"
          >
            <!-- <div class="text-h4 font-weight-medium mb-4 text-center">LOGIN</div> -->
            <!-- <div class="color-disable mb-4">
              Belum mempunyai akun ?
              <router-link
                class="font-weight-medium text-decoration-none"
                tabindex="-1"
                color="primary"
                :to="{ name: 'registration' }"
              >
                <span>Daftar sekarang</span>
              </router-link>
            </div> -->

            <v-alert
              type="error"
              text
              dense
              prominent
              v-show="response !== null"
              >{{ response }}</v-alert
            >
            <v-alert
              type="success"
              text
              dense
              prominent
              v-show="responsesukses !== null"
              >{{ responsesukses }}</v-alert
            >
            <v-row>
              <v-col class="pb-1">
                <div class="mb-2">
                  <span class="font-weight-medium">Email</span>
                </div>
                <v-text-field
                  class=""
                  v-model="email"
                  placeholder="Enter your email"
                  :rules="emailRules"
                  autofocus
                  required
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="mb-2">
                  <span class="font-weight-medium">Password</span>
                  <!-- <router-link
                    :to="{ name: 'password-send-email' }"
                    class="float-right text-decoration-none"
                    tabindex="-1"
                  >
                    Lupa Password?
                  </router-link> -->
                </div>
                <v-text-field
                  class=""
                  v-model="password"
                  placeholder="Min. 8 characters"
                  :rules="pswRules"
                  outlined
                  hide-details="auto"
                  required
                  :type="show ? 'text' : 'password'"
                  :append-icon="show ? 'ri-eye-fill' : 'ri-eye-off-fill'"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="">
                <v-btn
                  large
                  elevation="0"
                  type="submit"
                  color="primary"
                  :loading="submiting"
                  block
                >
                  Masuk
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "login",
  data() {
    return {
      show: false,
      errors: [],
      valid: true,
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      pswRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Password must be at least 8 characters",
      ],
      submiting: false,
      response: null,
      responsesukses: null,
      isInit: false,
      isSignIn: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {},
  created() {
    // // let that = this;
    // let checkGauthLoad = setInterval(function() {
    //   this.isInit = this.$gAuth.isInit;
    //   this.isSignIn = this.$gAuth.isAuthorized;
    //   if (this.isInit) clearInterval(checkGauthLoad);
    // }, 1000);
  },
  methods: {
    // OnGoogleAuthSuccess(idToken) {
    //   // console.log(idToken);
    //   // Receive the idToken and make your magic with the backend
    // },
    OnGoogleAuthFail(error) {
      console.log(error);
    },
    async loginWithFacebook() {
      var provider = new firebase.auth.FacebookAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          result;
          return;
          // var credential = result.credential;
          // console.log('Credential : ', credential);

          // const additionalUserInfo = result.additionalUserInfo;
          // console.log('additionalUserInfo : ', additionalUserInfo);

          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = credential.accessToken;
          // var IDtoken = credential.idToken;
          // console.log('Token : ', token);
          // console.log('IDtoken : ', IDtoken);
          // The signed-in user info.
          // var user = result.user;
          // console.log('user : ', user);

          // this.LoginSSO(additionalUserInfo, IDtoken)

          // setTimeout(() => {
          //   firebase.auth().signOut().then(() => {
          //     // Sign-out successful.
          //   }).catch((error) => {
          //     // An error happened.
          //   });
          // }, 10000)
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.error("errorCode : ", errorCode);
          console.error("errorMessage : ", errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.error("email : ", email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.error("credential : ", credential);
          // ...
        });

      // window.FB.login((response) => {
      //   if (response.status == "connected") {
      //     this.$store
      //       .dispatch("login_facebook", response.authResponse.accessToken)
      //       .then(() => {
      //         this.submiting = false;
      //         if (localStorage.getItem("ytc_lms_redirect") != null) {
      //           this.$router.push(localStorage.getItem("ytc_lms_redirect"));
      //           localStorage.removeItem("ytc_lms_redirect");
      //         } else {
      //           if(this.user.is_updated_profile){
      //             window.location.reload(true);
      //             this.$router.push("/epot");
      //           } else {
      //             // window.location.reload(true);
      //             this.$router.push("/account");

      //           }
      //           // this.$router.push("/dashboard");
      //         }
      //       })
      //       .catch((error) => {
      //         this.submiting = false;
      //         // this.$refs.form.reset();
      //         console.error(error);
      //         this.response = error;
      //       });
      //   }
      // });
    },
    async loginWithGoogle() {
      var provider = new firebase.auth.GoogleAuthProvider();

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          // console.log('Credential : ', credential);

          const additionalUserInfo = result.additionalUserInfo;
          // console.log('additionalUserInfo : ', additionalUserInfo);

          // This gives you a Google Access Token. You can use it to access the Google API.
          // var token = credential.accessToken;
          var IDtoken = credential.idToken;
          // console.log('Token : ', token);
          // console.log('IDtoken : ', IDtoken);
          // The signed-in user info.
          // var user = result.user;
          // console.log('user : ', user);

          this.LoginSSO(additionalUserInfo, IDtoken);
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.error("errorCode : ", errorCode);
          console.error("errorMessage : ", errorMessage);
          // The email of the user's account used.
          var email = error.email;
          console.error("email : ", email);
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.error("credential : ", credential);
          // ...
        });

      // const googleUser = await this.$gAuth.signIn();
      // // console.log('user',googleUser)
      // this.$store
      //   .dispatch("login_google", googleUser.wc.id_token)
      //   .then(() => {
      //     this.submiting = false;
      //     if (localStorage.getItem("ytc_lms_redirect") != null) {
      //       this.$router.push(localStorage.getItem("ytc_lms_redirect"));
      //       localStorage.removeItem("ytc_lms_redirect");
      //     } else {
      //       if(this.user.is_updated_profile){
      //             window.location.reload(true);
      //             this.$router.push("/epot");
      //           } else {
      //             // window.location.reload(true);
      //             this.$router.push("/account");

      //           }
      //       // this.$router.push("/dashboard");
      //     }
      //   })
      //   .catch((error) => {
      //     this.submiting = false;
      //     console.error('why',error);
      //     this.response = error;
      // });
    },

    async LoginSSO(user, IDtoken) {
      // console.log(user);
      // console.log(IDtoken);
      // console.clear()
      this.$store
        .dispatch("login_google", IDtoken)
        .then(() => {
          this.submiting = false;
          if (localStorage.getItem("ytc_lms_redirect") != null) {
            this.$router.push(localStorage.getItem("ytc_lms_redirect"));
            localStorage.removeItem("ytc_lms_redirect");
          } else {
            if (this.user.is_updated_profile) {
              window.location.reload(true);
              this.$router.push("/epot");
            } else {
              // window.location.reload(true);
              this.$router.push("/account");
            }
            // this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          this.submiting = false;
          // console.log('why',error.response);
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            // let errorHeader = error.response.headers;

            if (errorCode === 401) {
              this.response = errorData.message;
            } else {
              this.response = errorData.message;
            }
          } else {
            this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
          // this.response = error;
        });
    },
    submit() {
      this.submiting = true;
      if (this.$refs.form.validate()) {
        let email = this.email;
        let password = this.password;

        this.$store
          .dispatch("login", { email, password })
          .then(() => {
            this.submiting = false;
            if (localStorage.getItem("ytc_lms_redirect") != null) {
              this.$router.push(localStorage.getItem("ytc_lms_redirect"));
              localStorage.removeItem("ytc_lms_redirect");
            } else {
              if (this.user.is_updated_profile) {
                window.location.reload(true);
                this.$router.push("/epot");
              } else {
                // window.location.reload(true);
                this.$router.push("/account");
              }
            }
          })
          .catch((error) => {
            this.submiting = false;
            this.$refs.form.reset();
            // console.error(error);
            // this.response = "Email dan password tidak cocok";
            if (error.response) {
              let errorData = error.response.data;
              let errorCode = error.response.status;
              // let errorHeader = error.response.headers;

              if (errorCode === 401) {
                this.response = errorData.message;
              } else {
                this.response = errorData.message;
              }
            } else {
              this.$store.state.errorNetwork = true;
              this.process.run = false;
              this.process.form = false;
            }
          });
      }
      // this.$store
      //   .dispatch("login", { username, password })
      //   .then(() => {
      //     this.submiting = false;
      //     if (sessionStorage.getItem("redirect") != null) {
      //       this.$router.push(sessionStorage.getItem("redirect"));
      //       sessionStorage.removeItem("redirect");
      //       return;
      //     }
      //     this.$router.push("/dashboard");
      //   })
      //   .catch((errors) => {
      //     this.submiting = false;
      //     console.log(errors);
      //     this.response = "Akun ini tidak terdaftar";
      //     if (errors.response != undefined) {
      //       this.submiting = false;
      //       this.errors = errors.response.data.errors;
      //       this.response = "Akun ini tidak terdaftar";
      //     }
      //   });
      // }, 1000);
      // }
      else if (this.password.length < 8) {
        this.response = "password kurang dari 8 character";
        this.submiting = false;
      } else {
        this.submiting = false;
        this.response = "Email dan password tidak boleh kosong";
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style>
.google-signin-button {
  color: white;
  background-color: red;
  height: 50px;
  font-size: 16px;
  border-radius: 10px;
  padding: 10px 20px 25px 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.panduan-login {
  background: url("../../assets/images/logo-pnk.png");
  /* background: linear-gradient(rgba(106, 1, 106, 0.8), rgba(125, 47, 47, 0.8)),
    url("../../assets/images/login.png"); */
  /* background-size: contain; */
  background-repeat: no-repeat;
}
#line {
  width: 100%;
  height: max-content;
  text-align: center;
  position: relative;
}

#line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e0e6ed;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 1;
}

#line span {
  width: auto;
  background: #fff;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}
</style>
