<template>
  <div>
    <v-snackbar
      :timeout="snackbar.timeout"
      v-model="snackbar.state"
      top
      :color="snackbar.color"
      pre
      text>
      <v-icon left :color="snackbar.color">{{ snackbar.icon }}</v-icon>
      {{ snackbar.text }}
    </v-snackbar>

    <v-card
      rounded="lg"
      elevation="0"
      class="col-md-10 ma-auto my-10 px-2 py-10 text-center"
    >
      <div class="fs-24 color-state font-weight-bold mb-2">
        <span>{{ response }}</span>
      </div>
      <div class="">
        <p class="color-disable mb-0 fs-18" style="white-space: pre-line">{{ info_message }}</p>
        <p class="color-disable mb-0 fs-18" style="white-space: pre-line">{{ information }}</p>
        <div v-show="showGuide" class="col-md-4 ma-auto color-disable mb-0 fs-18" style="white-space: pre-line">
          <ol type="number" class="text-left">
            <li>Di kanan atas, Klik menu <v-icon>mdi-dots-vertical</v-icon></li>
            <li>Pilih Settings</li>
            <li>Pilih Privacy and security</li>
            <li>Pilih Site Settings</li>
            <li>Pilih situs EPT PNK</li>
            <li>Permissions Camera</li>
            <li>Izin Block diubah menjadi Allow</li>
          </ol>
        </div>
        <p v-show="showGuide" class="color-disable mb-0 fs-18" style="white-space: pre-line">Setelah Permissions berhasil diubah , Reload/Refresh halaman ini. </p>
      </div>
      <v-card-text class="pa-0">
        <div class="content-check">
          <div class="icon-cek" v-show="showIcon">
            <div class="pst">
              <v-progress-circular :size="50" color="#EFF2F7" indeterminate>
                <v-icon class="color-disable">ri-camera-fill</v-icon>
              </v-progress-circular>
            </div>
          </div>
          <div v-show="showVid">
            <img  
              v-if="detection_loading == true"
              style="
                position: absolute;
                object-position: center bottom;
                left:40.5%;
                top: 26.5%;"
              class="mx-auto"
              width="220"
              :src="require('@/assets/images/success_detection.png')"/>
            <img  
              v-if="detection_failed == true"
              style="
                position: absolute;
                object-position: center bottom;
                left:40.5%;
                top: 26.5%;"
              class="mx-auto"
              width="220"
              :src="require('@/assets/images/failed_detection.png')"/>
            <img  
              v-if="detection_successful == true"
              style="
                position: absolute;
                object-position: center bottom;
                left:40.5%;
                top: 26.5%;"
              class="mx-auto"
              width="220"
              :src="require('@/assets/images/frame_detection.png')"/>

          </div>
          <div v-show="showVid" class="my-3">
            <video
              v-if="showDetector"
              ref="video"
              class="access-video"
              id="video"
              width="500"
              height="250"
              @loadedmetadata="runModel"
              autoplay>
            </video>
            <video
              v-else
              ref="video"
              class="access-video"
              id="video"
              width="500"
              height="250"
              autoplay>
            </video>
            <canvas ref="canvas" />
          </div>
          <!-- v-show="lembaga.active_camera" -->
          <v-col cols="" md="10" class="ma-auto">
            <v-alert text warning>
              <span class="fs-14 color-warning-cutsom"
                >Izinkan penggunaan kamera yang terpasang di laptop/komputer Anda.
                Tes tidak dapat berjalan jika kamera dimatikan.</span
              >
            </v-alert>
          </v-col>
        </div>
      </v-card-text>

      <!-- # DIALOG DETECT MULTI FACE -->
      <v-dialog v-model="dialog.identification_face" persistent width="600">
        <v-card flat v-if="user.is_b2b == 0">
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Terdeteksi multi-wajah dalam pengerjaan tes
          </v-card-title>
          <v-card-text class="text-center">
            Sistem mendeteksi wajah lebih dari satu. Pastikan pengerjaan tes dilakukan secara jujur.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn 
              large
              @click="plus_counter_tab()" 
              color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card flat v-else class="pa-5">
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Terdeteksi multi-wajah dalam pengerjaan tes
          </v-card-title>
          <v-card-text class="text-center">
            Sistem mendeteksi wajah lebih dari satu. Pastikan pengerjaan tes dilakukan secara jujur.
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn 
              large
              @click="plus_counter_tab()"
              color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- # DIALOG DETECT NO FACE -->
      <v-dialog v-model="dialog.identification_no_face" persistent width="600">
        <v-card flat v-if="user.is_b2b == 0">
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Terdeteksi multi-wajah dalam pengerjaan tes
          </v-card-title>
          <v-card-text class="text-center">
            <v-img
              width="315"
              height="176"
              contain
              class="mx-auto"
              :src="imagePreview">
            </v-img>
            Sistem tidak mendeteksi wajah. Pastikan pengerjaan tes dilakukan secara jujur.
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn 
              large
              @click="plus_counter_tab()" 
              color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card flat v-else>
          <v-card-title style="color: #3C4858" class="font-weight-bold d-flex justify-center align-center">
            Terdeteksi multi-wajah dalam pengerjaan tes
          </v-card-title>
          <v-card-text class="text-center">
            <v-img
              width="315"
              height="176"
              contain
              class="mx-auto"
              :src="imagePreview">
            </v-img>
            Sistem tidak mendeteksi wajah. Pastikan pengerjaan tes dilakukan secara jujur.
          </v-card-text>
          <v-card-actions class="d-flex justify-center align-center">
            <v-btn 
              large
              @click="plus_counter_tab()"
              color="primary">
              Baik, Saya Mengerti
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import * as faceAPI from 'face-api.js'
export default {
  name: "epot-cekcam",
  component: {},
  data: function () {
    return {
      lembaga: {},
      response: "",
      information: "",
      info_message: "",
      imagePreview: "",
      showIcon: true,
      showVid: false,
      showGuide: false,
      showDetector: false,
      detection_failed: false,
      detection_loading: false,
      detection_successful: false,
      board: {
        realTimeCountsFace: 0,
      },
      snackbar: {
        state: false,
        text: "",
        color: "",
        icon: ""
      },
      dialog: {
        identification_face: false,
        identification_no_face: false,
      },
    };
  },
  created() {
    // console.log('id cam',this.$route.params.id)
  },
  watch: {
    'board.realTimeCountsFace': async function(face) {
      console.log(face);
      let vm = this
      if (face == 0) {
        vm.detection_failed = true
        vm.detection_loading = false
        vm.detection_successful = false
        vm.snackbar.state = true
        vm.snackbar.timeout = 3000
        vm.snackbar.text = "Kamera tidak dapat mendeteksi wajah anda"
        vm.snackbar.color = "red accent-2"
        vm.snackbar.icon = "mdi-information"
      }
      if (face > 1) {
        vm.detection_failed = true
        vm.detection_loading = false
        vm.detection_successful = false
        vm.snackbar.state = true
        vm.snackbar.timeout = 3000
        vm.snackbar.text = "Kamera mendeteksi terdapat lebih dari 1 wajah"
        vm.snackbar.color = "red accent-2"
        vm.snackbar.icon = "mdi-information-circle"
      }

      if (face == 1) {
        vm.detection_failed = false
        vm.detection_loading = false
        vm.detection_successful = true
        vm.snackbar.state = true
        vm.snackbar.timeout = 3000
        vm.snackbar.text = "Kamera berhasil mendeteksi wajah dengan baik"
        vm.snackbar.color = "success"
        vm.snackbar.icon = "mdi-check-circle"
        // setTimeout(() => {
        //   vm.axios.put(`/users/v1/member/order/update-start-test/${vm.$route.params.id}`, {}).then(() => {
        //     vm.$route.meta.prevent_move = false;
        //     window.onbeforeunload = null;
        //     vm.$router.push({ name: "epot-listening", params: { id: vm.$route.params.id } });
        //   })
        //   // this.$router.push({ name: "epot-headset-check" , params: { id: this.$route.params.id } });
        // }, 10000);
      }
    }
  },
  computed: {
    user(){
      return JSON.parse(localStorage.getItem('ytc_lms_auth'));
    },
    initParams() {
      const data = {
        modelUri: '/models',
        // option: new faceAPI.SsdMobilenetv1Options({ minConfidence: 0.5 })
        // option: new faceAPI.TinyFaceDetectorOptions({ inputSize: 320 })
        option: new faceAPI.MtcnnOptions({minFaceSize: 20})
      }
      return data
    }
  },
  async mounted() {
    this.response = "Sedang memeriksa perangkat anda...";
    this.info_message = "Pastikan wajah anda terlihat di kamera dan tidak ada orang lain selama tes berlangsung"
    this.information =
      "Proses ini berjalan secara otomatis dan mungkin membutuhkan waktu beberapa saat. Harap menunggu.";
     window.onbeforeunload = function (e) {
      e.preventDefault();
      e.returnValue = "";
    };

    await this.initModel()
    // this.startStream()


    // # IF User B2B
    // if(this.user.institusion.id != ''){
    //   await this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
    //     let res = response.data;
    //     if (res.status == 200) {
    //       this.lembaga = res.data
    //     } else{
    //       console.log("hasil2", response);
    //     }
    //   })
    //   if(this.lembaga.active_camera){
    //     this.$route.meta.prevent_move = true;
    //     navigator.mediaDevices
    //       .getUserMedia({ video: true })
    //       .then((mediaStream) => {
    //         console.log(mediaStream);
    //         if (mediaStream) {
    //           this.$refs.video.srcObject = mediaStream;
    //           this.$refs.video.play();
    //           this.response = "Berhasil menyambungkan perangkat anda";
    //           this.showVid = true;
    //           this.showIcon = false;
    //           // setTimeout(() => {
    //           //   this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
    //           //     this.$route.meta.prevent_move = false;
    //           //     window.onbeforeunload = null;
    //           //     this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
    //           //   })
    //           //   // this.$router.push({ name: "epot-headset-check" , params: { id: this.$route.params.id } });
    //           // }, 10000);
    //         }else{
    //           console.log('here not camera');
    //         }
    //       })
    //       .catch((error) => {
    //         this.response = "Gagal menyambungkan perangkat anda";
    //         this.information =
    //           "Silahkan Cek Kembali Permission Access Device di browser Anda.";
    //         this.showGuide=true;
    //         this.showIcon = false;
    //         console.error("getUserMedia() error:", error);
    //       });
    //   }else{
    //     this.response ="Anda diperbolehkan tidak menggunakan kamera.";
    //     this.showVid = true;
    //     this.showIcon = false;
    //     setTimeout(() => {
    //       this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
    //         window.onbeforeunload = null;
    //         this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
    //       })
    //     }, 5000);
    //   }
    // }

    // if (this.user.institusion.id == '') {
    //   this.$route.meta.prevent_move = true;
    //   navigator.mediaDevices
    //     .getUserMedia({ video: true })
    //     .then((mediaStream) => {
    //       this.$refs.video.srcObject = mediaStream;
    //       this.$refs.video.play();
    //       this.response = "Berhasil menyambungkan perangkat anda";
    //       this.showVid = true;
    //       this.showIcon = false;
    //       this.initModel()
    //       this.runModel()
    //       // setTimeout(() => {
    //       //   this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
    //       //     this.$route.meta.prevent_move = false;
    //       //     window.onbeforeunload = null;
    //       //     this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
    //       //   })
    //       //   // this.$router.push({ name: "epot-headset-check" , params: { id: this.$route.params.id } });
    //       // }, 10000);
    //     })
    //     .catch((error) => {
    //       this.response = "Gagal menyambungkan perangkat anda";
    //       this.information =
    //         "Silahkan Cek Kembali Permission Access Device di browser Anda.";
    //       this.showGuide=true;
    //       this.showIcon = false;
    //       console.error("getUserMedia() error:", error);
    //     });
    // }

  },
  beforeDestroy() {
    window.onbeforeunload = null
  },
  methods: {
    // CAMERA STREAM
    async startStream() {

      // # IF User B2B
      if(this.user.is_b2b == 1){
        this.detection_loading = true;
        await this.axios.get(`/institusion/v1/get/institusion/detail/${this.user.institusion.id}`).then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.lembaga = res.data
          } else{
            // console.log("hasil2", response);
          }
        })
        if(this.lembaga.active_camera){
          this.$route.meta.prevent_move = true;
          navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((mediaStream) => {
              if (mediaStream) {
                this.$refs.video.srcObject = mediaStream;
                this.$refs.video.play();
                this.response = "Berhasil menyambungkan perangkat anda";
                this.info_message = "Pastikan wajah anda terlihat di kamera dan tidak ada orang lain selama tes berlangsung"
                this.showVid = true;
                this.showIcon = false;
                if (this.lembaga.setting.identification.face) {
                  this.showDetector = true
                  this.detection_loading = false;
                  this.detection_failed = false;
                  this.detection_successful = true;
                  this.runModel()
                }else {
                  this.showDetector = false
                  this.detection_loading = false;
                  this.detection_failed = false;
                  this.detection_successful = true;
                  // setTimeout(() => {
                  //   this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
                  //     this.$route.meta.prevent_move = false;
                  //     window.onbeforeunload = null;
                  //     this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
                  //   })
                  //   // this.$router.push({ name: "epot-headset-check" , params: { id: this.$route.params.id } });
                  // }, 10000);
                }
              }else{
                console.log('here not camera');
              }
            })
            .catch((error) => {
              this.response = "Gagal menyambungkan perangkat anda";
              this.information =
                "Silahkan Cek Kembali Permission Access Device di browser Anda.";
              this.showGuide=true;
              this.showIcon = false;
              console.error("getUserMedia() error:", error);
            });
        }else{
          this.detection_loading = true;
          this.detection_failed = false;
          this.detection_successful = false;
          this.response ="Anda diperbolehkan tidak menggunakan kamera.";
          this.showVid = true;
          this.showIcon = false;
          // setTimeout(() => {
          //   this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
          //     window.onbeforeunload = null;
          //     this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
          //   })
          // }, 10000);
        }
      }

      if (this.user.is_b2b == 0) {
        this.showDetector = true
        this.detection_loading = true;
        this.$route.meta.prevent_move = true;
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((mediaStream) => {
            this.$refs.video.srcObject = mediaStream;
            this.$refs.video.play();
            this.response = "Berhasil menyambungkan perangkat anda";
            this.info_message = "Pastikan wajah anda terlihat di kamera dan tidak ada orang lain selama tes berlangsung"
            this.showVid = true;
            this.showIcon = false;
            this.showDetector = true
            this.detection_loading = true;
            this.detection_failed = false;
            this.detection_successful = true;
            this.runModel()
            // setTimeout(() => {
            //   this.axios.put('/users/v1/member/order/update-start-test/'+this.$route.params.id, {}).then(() => {
            //     this.$route.meta.prevent_move = false;
            //     window.onbeforeunload = null;
            //     this.$router.push({ name: "epot-listening", params: { id: this.$route.params.id } });
            //   })
            //   // this.$router.push({ name: "epot-headset-check" , params: { id: this.$route.params.id } });
            // }, 10000);
          })
          .catch((error) => {
            this.response = "Gagal menyambungkan perangkat anda";
            this.information =
              "Silahkan Cek Kembali Permission Access Device di browser Anda.";
            this.showGuide=true;
            this.showIcon = false;
            this.detection_loading = false;
            this.detection_failed = true;
            this.detection_successful = false;
            console.error("getUserMedia() error:", error);
          });
      }
    },

    // FACE DETECTION
    async initModel(){
      await faceAPI.nets.ssdMobilenetv1.loadFromUri(this.initParams.modelUri)
      await faceAPI.nets.mtcnn.loadFromUri(this.initParams.modelUri)
      await faceAPI.nets.tinyFaceDetector.loadFromUri(this.initParams.modelUri)
      await faceAPI.nets.faceRecognitionNet.loadFromUri(this.initParams.modelUri)
      await faceAPI.nets.faceLandmark68Net.loadFromUri(this.initParams.modelUri)
      // await faceAPI.nets.ageGenderNet.loadFromUri(this.initParams.modelUri)
      this.startStream()
    },
    async runModel(){
      const result = await faceAPI.detectAllFaces(this.$refs.video, this.initParams.option).withFaceLandmarks()

      let canvas = this.$refs.canvas;
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      if (result) {
        const dims = faceAPI.matchDimensions(canvas, this.$refs.video, true)
        const resizeResults = faceAPI.resizeResults(result, dims)
        this.board.realTimeCountsFace = resizeResults.length
        if (this.board.realTimeCountsFace == 0) {
          this.snackbar.state = true
          this.snackbar.timeout = -1
          this.snackbar.text = "Kamera tidak dapat mendeteksi wajah anda"
          this.snackbar.color = "red accent-2"
          this.snackbar.icon = "mdi-information"
          this.detection_loading = false;
          this.detection_failed = true;
          this.detection_successful = false;
        }
        if (this.board.realTimeCountsFace > 1) {
          this.snackbar.state = true
          this.snackbar.timeout = -1
          this.snackbar.text = "Kamera mendeteksi terdapat lebih dari 1 wajah"
          this.snackbar.color = "red accent-2"
          this.snackbar.icon = "mdi-information-circle"
          this.detection_loading = false;
          this.detection_failed = true;
          this.detection_successful = false;
        }
        // faceAPI.draw.drawDetections(canvas, resizeResults)
        faceAPI.draw.drawFaceLandmarks(canvas, resizeResults)
      }
      setTimeout(() => this.runModel())
    },

    plus_counter_tab() {
      this.dialog.identification_face = false
      this.dialog.identification_no_face = false
    },
    async capture_image() {
      let canvas = this.$refs.canvas;
      let ctx = canvas.getContext("2d");
      let vid = this.$refs.video;

      canvas.width = vid.width;
      canvas.height = vid.height;
      ctx.drawImage(vid, 0, 0, canvas.width, canvas.height);

      canvas.toBlob((blob) => {
        let form_data = new FormData();
        form_data.append("title", `face-detection-1`);
        form_data.append("source", "upload");
        form_data.append(
          "file",
          blob,
          `face-detection-1.jpg`
        );
        this.$store
          .dispatch("media/create", form_data)
          .then((res) => res.data)
          .then((data) => {
            this.imagePreview = data.public_url

            if (this.board.realTimeCountsFace == 0) {
              this.dialog.identification_no_face = true
            }
            // console.log(data.id);
            // this.images.push(data.id);
          });
      }, "image/jpeg");
    }
  },
};
</script>

<style scoped>
.v-progress-circular {
  margin: 0.5rem;
}
.color-warning-cutsom {
  color: #8c7913 !important;
}
.icon-cek {
  height: 200px;
  padding: 10px;
  position: relative;
}
.pst {
  position: absolute;
  right: 0;
  left: 0;
  top: 40%;
}
.content-check .v-alert--text:before {
  background-color: #fefbd8 !important;
}
.content-check .v-alert--text {
  background: #fefbd8 !important;
}
.pst .v-progress-circular__underlay {
  stroke: transparent !important;
  z-index: 1;
}
.access-video {
  background-color: #000;
  border-radius: 5px;
  /* object-fit: cover; */
  /*  position: fixed;
  right: 0;
  bottom: 5%;*/
}
canvas {
  position: absolute;
  right: 28%;
  z-index: 10;
  width: 500px;
  height: 250px;
}
</style>