var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[(_vm.loaded)?_c('v-app',[_c('div',[_c('v-navigation-drawer',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_sidebar),expression:"show_sidebar"}],attrs:{"height":"100vh","fixed":"","floating":"","permanent":_vm.$vuetify.breakpoint.mdAndUp,"temporary":!_vm.$vuetify.breakpoint.mdAndUp},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"mx-auto my-1 text-center"},[_c('img',{staticClass:"img-yec",attrs:{"src":require("@/assets/images/logo-pnk.png"),"alt":"Avatar"}})]),_c('v-divider'),_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_vm._l((_vm.menus),function(item){return [_c('v-list-item',{key:item.link,staticClass:"py-1 pl-6 font-weight-medium",attrs:{"disabled":!_vm.user.is_updated_profile && item.title != 'Account',"active-class":"menu-active","to":item.link},on:{"click":function($event){return _vm.pageChanged(0, item)}}},[_c('v-list-item-icon',{staticClass:"mr-4 fs-14"},[_c('v-icon',{staticClass:"color--text",class:_vm.user.is_updated_profile ||
                      item.title == 'Account' ||
                      'text--lighten-3',domProps:{"textContent":_vm._s(item.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fs-14 color--text",class:_vm.user.is_updated_profile ||
                      item.title == 'Account' ||
                      'text--lighten-3'},[_vm._v(_vm._s(item.title))])],1)],1)]})],2)],1)],1),_c('v-main',{style:({
          marginLeft:
            _vm.show_sidebar && _vm.$vuetify.breakpoint.mdAndUp ? '256px' : 0,
        })},[_c('v-app-bar',{style:({
            marginLeft:
              _vm.show_sidebar && _vm.$vuetify.breakpoint.mdAndUp ? '256px' : 0,
          }),attrs:{"flat":"","fixed":""}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = true}}}):_vm._e(),_c('v-toolbar-title',{directives:[{name:"show",rawName:"v-show",value:(!_vm.show_sidebar),expression:"!show_sidebar"}]},[_c('div',{staticClass:"my-1 text-left"},[_c('img',{staticClass:"img-yec-test",attrs:{"src":require("@/assets/images/logo-pnk.png"),"alt":"Avatar"}})])]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"ml-6 rounded-pill"},'div',attrs,false),on),[_c('v-avatar',{staticClass:"elevation-0 white mr-2",attrs:{"size":"40px"}},[_c('div',{staticClass:"pa-10 bg-grey rounded-circle d-inline-block mb-3 fs-20"},[_c('v-icon',[_vm._v("ri-user-line")])],1)]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.user.fullname))]),_c('v-icon',{staticClass:"mx-2",attrs:{"small":""}},[_vm._v("$dropdown")])],1)]}}],null,false,2037202792)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s('Logout')}})],1)],1)],1)],1),_c('v-container',{staticStyle:{"margin-top":"64px"},attrs:{"fluid":""}},[_c('div',{staticClass:"content-wrapper mx-2"},[_c('transition',{attrs:{"name":"fade-up"}},[(_vm.loaded)?_c('router-view'):_vm._e()],1)],1)])],1)],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }