import Vue from 'vue'
import Vuetify from 'vuetify'
import 'remixicon/fonts/remixicon.css'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const MY_ICONS = {
  // menu
  dashboard: 'ri-dashboard-3-line',
  epot: 'ri-pencil-ruler-line',
  account: 'ri-user-line',
  notification:'ri-notification-2-fill',
  guide:'ri-profile-line',
  exercise:'ri-book-read-line',
  dropdown: 'ri-arrow-down-s-line',

  arrdown:'ri-arrow-down-s-fill',
  next:'ri-arrow-right-s-line',
  prev:'ri-arrow-left-s-line',
  checkboxOff:'ri-checkbox-blank-line',
  checkboxOn:'ri-checkbox-fill',
  expand:'ri-arrow-down-s-line'
}

const opts = {
	theme: {
    themes: {
      light: {
        background: '#F5F7F9',
        color: '#3C4858',
        primary: '#00549a',
        secondary:'#FEFBD8'
      }
    },
    options: {
      customProperties: true
    },
  },
  icons: {
    values: MY_ICONS,
    iconfont: 'mdi',
  },
}
export default new Vuetify(opts)