const OSs = [
  "CrOS x86",
  "Linux x86",
  "Linux arm",
  "Linux i686",
  "Mac_PowerPC",
  "Macintosh",
  "Mac OS X",
  "Windows NT",
];

const xOSs = ["iPhone", "iPad"];

if (
  !OSs.some((v) => navigator.userAgent.includes(v)) ||
  xOSs.some((v) => navigator.userAgent.includes(v))
) {
  const message = "Mohon maaf Tes EPT PNK tidak dapat diakses dari handphone/tablet. Silahkan akses menggunakan laptop/komputer PC.";
  document.querySelector("body").innerHTML = `<h1>${message}</h1>`;
  throw new Error(message);
}

require("./lib");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Firebase from "./lib/Firebase";
import vuetify from "./lib/vuetify";
import StoreInit from "./system/StoreInit.js";
import VueGtagPlugin from "vue-gtag";
import convert from "./lib/convert";
import VueAnalytics from 'vue-analytics';

// import vuepaper from './lib/vuepaper'

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-116046913-1',
  router
});

/* =========== AUTH ================ */
let userdata = StoreInit.getUserdata();
let tokenLS = StoreInit.get("token");
if (tokenLS) {
  store.dispatch("user/setToken", tokenLS);
  store.dispatch("user/setUserData", userdata);
}

Vue.config.productionTip = false;

store.dispatch("get_user");

Vue.use(VueGtagPlugin, { config: { id: "G-DN4PMRD19M" } }, router);

new Vue({
  router,
  store,
  vuetify,
  Firebase,
  convert,
  // vuepaper,
  render: (h) => h(App),
}).$mount("#app");
